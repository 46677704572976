import Footer from "../../components/Footer";
import UpcycledHero from "../../components/UpcycledHero";
import Reusable from "../../components/Reusable";

export default function index() {
  return (
    <>
      <UpcycledHero />
      <Reusable />
      <Footer />
    </>
  );
}
