import process1 from "../../assets/img/process-1.webp";
import process2 from "../../assets/img/process2.png";
import process3 from "../../assets/img/process3.png";

export default function index() {
  return (
    <div className="bg">
      <div className="mx-auto max-w-7xl px-6 lg:px-0 py-16">
        <h1 className="flex gap-4 text-[#5E3110] font-bold text-4xl md:text-[4rem]">Our <h1 className="text-[#116835]">Process</h1></h1>
        <div className="flex items-center justify-between py-6 md:py-0">
          <div className="w-1/3 md:w-1/2">
            <div className="py-12 flex justify-start">
              <img src={process1} className="h-32 md:h-64" alt="process" />
            </div>
          </div>
          <div className="w-2/3 md:w-1/2">
            <div className="flex flex-col gap-4">
              <h2 className="text-black text-[20px] md:text-[2.25rem] font-bold">
                Spent Coffee Grounds
              </h2>
              <p className="text-[15px] md:text-[1.5rem] text-black">
                Our waste management processes manage the coffee grounds by
                collecting them from headquarters, offices, and hotels. From
                weekly operations to monthly carbon emission reporting,we
                include businesses on a sustainable agenda.
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between py-6 md:py-0">
          <div className="w-1/3 md:w-1/2">
            <div className="py-12 flex justify-end">
              <img src={process2} className="h-32 md:h-64" alt="process" />
            </div>
          </div>
          <div className="w-2/3 md:w-1/2">
            <div className="flex flex-col gap-4">
              <h2 className="text-black text-[20px] md:text-[2.25rem] font-bold">
                Espresso Granular
              </h2>
              <p className="text-[15px] md:text-[1.5rem] text-black">
                The spent coffee grounds collected weekly is compounded with
                biodegradable polymers to produce a bio-raw material substitute
                for plastics. From automotive to home appliances, our products
                have been delivered to the needs of various sectors.
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between py-6 md:py-0">
          <div className="w-1/3 md:w-1/2">
            <div className="py-12 flex justify-center">
              <img src={process3} className="h-32 md:h-64" alt="process" />
            </div>
          </div>
          <div className="w-2/3 md:w-1/2">
            <div className="flex flex-col gap-4">
              <h2 className="text-black text-[20px] md:text-[2.25rem] font-bold">
                Products
              </h2>
              <p className="text-[15px] md:text-[1.5rem] text-black">
                 Spent coffee grounds are up-cycled into bio-based and reusable
                products such as coffee cups, straws, and cutlery sets. They are
                environment-friendly products suitable for daily usage as
                substitutes for single-use plastics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
