import Home from "./pages/Home";
import MıcroWaste from "./pages/MıcroWaste";
import UpCycledProducts from "./pages/UpCycledProducts";
import EspressoGranule from "./pages/EspressoGranule";
import AboutUs from "./pages/AboutUs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <Routes>
      <Route exact path="" element={<Home />} />
      <Route exact path="micro-waste-managment" element={<MıcroWaste />} />
      <Route exact path="upcycled-products" element={<UpCycledProducts />} />
      <Route exact path="espresso-granule" element={<EspressoGranule />} />
      <Route exact path="about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
