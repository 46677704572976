import Footer from "../../components/Footer";
import AboutUs from "../../components/AboutUs";
export default function index() {
  return (
    <>
      <AboutUs />
      <Footer />
    </>
  );
}
