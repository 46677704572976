import Footer from '../../components/Footer'
import GranuleHero from '../../components/GranuleHero'
import MaterialProcess from '../../components/MaterialProcess'
export default function index() {
  return (
   <>
   <GranuleHero/>
   <MaterialProcess/>
   <Footer/>
   </>
  )
}
