import granule from "../../assets/img/granule.webp";

export default function index() {
  return (
    <div className="bg relative overflow-hidden flex md:block">
      <div className="py-8 md:pt-24 md:pb-12  px-6 md:px-0 md:pl-24 max-w-4xl">
        <h2 className="text-[#1E1E1E] text-[20px] md:text-[3.625rem] font-bold uppercase leading-tight">
          What Is <h2 className="text-[#116835]">wastespresso?</h2>
        </h2>
        <div className="flex flex-col pt-12 pb-6 gap-4">
          <p className="text-[#1E1E1E] text-[11px] md:text-[1.5rem] font-medium">
            Wastespresso is a waste management company that up-cycles spent
            coffee grounds to eliminate plastic usage. We manage the coffee
            wasted at headquarters, offices, and hotels to produce bio-raw
            material and customized products that are substitutes for plastics.
          </p>
          <p className="text-[#1E1E1E] text-[11px] md:text-[1.5rem] font-medium">
            With Wastespresso, you can collaborate in our efforts to create a
            circular economy compatible with our environment.
          </p>
        </div>
      </div>
      <div className="relative md:absolute -right-8 md:right-0 top-0">
        <img src={granule} className='w-[100vh] h-full md:w-[26rem]' alt="hero" />
      </div>
    </div>
  );
}
