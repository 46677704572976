import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import upcycledhero from "../../assets/img/upcycled-products.png";

export default function Index() {
  const [dropdownMenu, setDropdownMenu] = useState(false);
  return (
    <div className="bg relative overflow-hidden">
      <div className="block md:hidden bg-[#1B3C1E] max-w-4xl rounded-br-[1.875rem]">
        <div className="px-6 py-3">
          <div className="relative flex items-center justify-center md:justify-normal gap-16">
            <div className="block md:hidden absolute left-0">
              <div
                onClick={() => setDropdownMenu(!dropdownMenu)}
                className="bg-white p-2 rounded-lg"
              >
                <MdOutlineMenu className="w-6 h-6 text-[#1B3C1E]" />
              </div>
              {dropdownMenu && (
                <div className="absolute bg-white rounded-md w-48 mt-2 z-10">
                  <ul>
                    <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                      <NavLink to="/micro-waste-managment">
                        Micro Waste Managment
                      </NavLink>
                    </li>
                    <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                      <NavLink to="/upcycled-products">
                        Upcycled Products
                      </NavLink>
                    </li>
                    <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                      <NavLink to="/espresso-granule">Espresso Granule</NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <NavLink to="/">
              {" "}
              <img src={logo} className="h-12" alt="logo" />
            </NavLink>
            <nav className="hidden md:block">
              <ul className="flex items-center gap-8">
                <li className="text-white uppercase text-lg font-semibold">
                  <NavLink to="/about-us">ABOUT US</NavLink>
                </li>
                <li className="text-white uppercase text-lg font-semibold relative">
                  <NavLink
                    onClick={() => setDropdownMenu(!dropdownMenu)}
                    className="flex gap-2 items-center"
                  >
                    PRODUCTS & SERVICES <ArrowDownIcon className="w-4 h-4" />
                  </NavLink>
                  {dropdownMenu && (
                    <div className="absolute bg-white rounded-md w-full">
                      <ul>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/micro-waste-managment">
                            Micro Waste Managment
                          </NavLink>
                        </li>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/upcycled-products">
                            Upcycled Products
                          </NavLink>
                        </li>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/espresso-granule">
                            Espresso Granule
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-24">
        <div className="hidden md:block w-1/3">
          <img src={upcycledhero} className="w-auto h-auto" alt="hero" />
        </div>
        <div className="w-full md:w-2/3">
          <div className="hidden md:block bg-[#1B3C1E] rounded-bl-[1.875rem]">
            <div className="px-6 py-3">
              <div className="flex items-center gap-16">
                <NavLink to="/">
                  {" "}
                  <img src={logo} className="h-12" alt="logo" />
                </NavLink>
                <nav>
                  <ul className="flex items-center gap-8">
                    <li className="text-white uppercase text-lg font-semibold">
                      <NavLink to="/about-us">ABOUT US</NavLink>
                    </li>
                    <li className="text-white uppercase text-lg font-semibold relative">
                        <NavLink onClick={()=> setDropdownMenu(!dropdownMenu)} className='flex gap-2 items-center'>PRODUCTS & SERVICES <ArrowDownIcon className="w-4 h-4"/></NavLink>
                        {dropdownMenu && <div className="absolute bg-white rounded-md w-full">
                          <ul>
                            <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                              <NavLink to='/micro-waste-managment'>Micro Waste Managment</NavLink>
                            </li>
                            <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                              <NavLink to='/upcycled-products'>Upcycled Products</NavLink>
                            </li>
                            <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                              <NavLink to='/espresso-granule'>Espresso Granule</NavLink>
                            </li>
                          </ul>
                          </div>}
                      </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 py-12 md:py-24">
            <h1 className="text-[#1E1E1E] text-[24px] md:text-[4rem] font-bold leading-tight uppercase text-center md:text-left">
              Upcycled Products
            </h1>
            <p className="w-full md:w-[70%] text-[#1E1E1E] text-[11px] md:text-[1.5rem] font-medium px-6 md:px-0 text-center md:text-left">
              We actively contribute to a circular economy model by crafting
              eco-friendly products from spent coffee grounds collected through
              our micro-waste management process. <br/><br/>Our product range includes
              coffee cups, straws, and cutlery sets. Whether or not you're part
              of our waste management system, you can support sustainability
              through our products. We provide businesses an innovative option
              of producing these items from their spent coffee grounds. <br/><br/>This
              underscores our dedication to fostering a circular economy that is
              accessible, collaborative, and tailored to the unique needs of
              diverse stakeholders.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
