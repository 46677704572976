import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import {
  ArrowDownIcon,
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index() {
  const [dropdownMenu, setDropdownMenu] = useState(false);

  const form = useRef();
  const notify = () =>
    toast.success("Thanks, we will contact you as soon as possible", {
      position: "bottom-left",
    });
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fgmvxrx",
        "template_teqlbnx",
        form.current,
        "JOdMzgg4mkAGC_A6C"
      )
      .then(
        (result) => {
          notify();
          form.current.reset();
        },
        (error) => {
          toast.error("There was a problem, please try again",
            {
              position: "bottom-left",
            });
        }
      );
  };
  return (
    <>
      <ToastContainer />
      <div className="bg relative overflow-hidden">
        <div className="block md:hidden bg-[#1B3C1E] max-w-4xl rounded-br-[1.875rem]">
          <div className="px-6 py-3">
            <div className="relative flex items-center justify-center md:justify-normal gap-16">
              <div className="block md:hidden absolute left-0">
                <div
                  onClick={() => setDropdownMenu(!dropdownMenu)}
                  className="bg-white p-2 rounded-lg"
                >
                  <MdOutlineMenu className="w-6 h-6 text-[#1B3C1E]" />
                </div>
                {dropdownMenu && (
                  <div className="absolute bg-white rounded-md w-48 mt-2 z-10">
                    <ul>
                      <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                        <NavLink to="/micro-waste-managment">
                          Micro Waste Managment
                        </NavLink>
                      </li>
                      <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                        <NavLink to="/upcycled-products">
                          Upcycled Products
                        </NavLink>
                      </li>
                      <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                        <NavLink to="/espresso-granule">
                          Espresso Granule
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <NavLink to="/">
                {" "}
                <img src={logo} className="h-12" alt="logo" />
              </NavLink>
              <nav className="hidden md:block">
                <ul className="flex items-center gap-8">
                  <li className="text-white uppercase text-lg font-semibold">
                    <NavLink to="/about-us">ABOUT US</NavLink>
                  </li>
                  <li className="text-white uppercase text-lg font-semibold relative">
                    <NavLink
                      onClick={() => setDropdownMenu(!dropdownMenu)}
                      className="flex gap-2 items-center"
                    >
                      PRODUCTS & SERVICES <ArrowDownIcon className="w-4 h-4" />
                    </NavLink>
                    {dropdownMenu && (
                      <div className="absolute bg-white rounded-md w-full">
                        <ul>
                          <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                            <NavLink to="/micro-waste-managment">
                              Micro Waste Managment
                            </NavLink>
                          </li>
                          <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                            <NavLink to="/upcycled-products">
                              Upcycled Products
                            </NavLink>
                          </li>
                          <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                            <NavLink to="/espresso-granule">
                              Espresso Granule
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="flex justify-start md:justify-end  px-8 md:px-0">
          <div className="w-full md:w-2/3">
            <div className="hidden md:block bg-[#1B3C1E] rounded-bl-[1.875rem]">
              <div className="px-6 py-3">
                <div className="flex items-center gap-16">
                  <NavLink to="/">
                    {" "}
                    <img src={logo} className="h-12" alt="logo" />
                  </NavLink>
                  <nav>
                    <ul className="flex items-center gap-8">
                      <li className="text-white uppercase text-lg font-semibold">
                        <NavLink to="/about-us">ABOUT US</NavLink>
                      </li>
                      <li className="text-white uppercase text-lg font-semibold relative">
                        <NavLink
                          onClick={() => setDropdownMenu(!dropdownMenu)}
                          className="flex gap-2 items-center"
                        >
                          PRODUCTS & SERVICES{" "}
                          <ArrowDownIcon className="w-4 h-4" />
                        </NavLink>
                        {dropdownMenu && (
                          <div className="absolute bg-white rounded-md w-full">
                            <ul>
                              <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                                <NavLink to="/micro-waste-managment">
                                  Micro Waste Managment
                                </NavLink>
                              </li>
                              <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                                <NavLink to="/upcycled-products">
                                  Upcycled Products
                                </NavLink>
                              </li>
                              <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                                <NavLink to="/espresso-granule">
                                  Espresso Granule
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <div className="py-24">
              <h1 className="text-left text-[#1E1E1E] text-[3rem] md:text-[4rem] font-bold leading-tight uppercase">
                our story
              </h1>
              <p className="w-full md:w-[70%] text-[#1E1E1E] text-lg md:text-[1.5rem] font-medium pt-4 md:pt-0">
                In the heart of innovation and sustainability, Wastespresso was
                born from a shared vision to redefine waste, transform the
                future, and pioneer sustainable solutions. It all began with a
                simple yet powerful question: "How can we turn spent coffee
                grounds into a valuable resource for a greener planet?"
                <br />
                <br /> At Wastespresso, our dedicated team of scientists,
                engineers, and sustainability advocates embarked on a relentless
                pursuit of innovative processes to upcycle coffee grounds into
                high-quality bio-raw materials. We saw the potential to reduce
                the strain on our environment and create sustainable
                alternatives for the plastic and packaging industries.
                <br />
                <br /> After relentless research and experimentation, we
                pioneered technologies that not only unlocked the hidden
                potential in coffee grounds but also fostered waste management
                system attaining a sustainable and carbon-neutral stance. <br />
                <br />
                Through partnerships we have established, we expand our reach,
                influence, and impact, pushing the boundaries of sustainable
                solutions. We are confident that by joining forces, we can pave
                the way to a more sustainable future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#1B3C1E] border-b border-[#E1E1E180]">
        <div className="mx-auto max-w-3xl px-6 lg:px-0 py-16">
          <div className="text-center">
            <h2 className="text-[#E1E1E1] text-[2.5rem] md:text-[3rem] font-bold">
              Mission
            </h2>
            <p className="text-[#E1E1E1] text-xl md:text-[2rem] font-medium">
              "At Wastespresso, our mission is to revolutionize the
              sustainability landscape by upcycling coffee waste into
              high-quality bio-raw materials. Through innovative processes and
              partnerships, we strive to create a cleaner, greener future for
              the plastic and packaging industries while promoting responsible
              waste management and carbon neutrality."
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#1B3C1E]">
        <div className="mx-auto max-w-3xl px-6 lg:px-0 py-16">
          <div className="text-center">
            <h2 className="text-[#E1E1E1] text-[2.5rem] md:text-[3rem] font-bold">
              Vision
            </h2>
            <p className="text-[#E1E1E1] text-xl md:text-[2rem] font-medium">
              "Wastespresso envisions a world where coffee waste becomes a
              valuable resource, fueling a circular economy. We aim to be a
              global leader in bio-raw material production, providing
              sustainable solutions that empower industries to reduce their
              environmental impact and embrace eco-friendly alternatives. Our
              vision is to inspire a collective effort towards a more
              sustainable and regenerative planet."
            </p>
          </div>
        </div>
      </div>
      <div className="relative isolate bg">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
              <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                <svg
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      x="100%"
                      y={-1}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M130 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                  </defs>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="white"
                  />
                  <svg
                    x="100%"
                    y={-1}
                    className="overflow-visible fill-gray-50"
                  >
                    <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                  />
                </svg>
              </div>
              <h2 className="text-[3rem] font-bold tracking-tight text-[#1E1E1E]">
                Contact Us
              </h2>
              <p className="mt-6 text-lg leading-8 text-[#1E1E1E]">
                Contact us for a more sustainable world!
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-[#1E1E1E]">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Address</span>
                    <BuildingOffice2Icon
                      className="h-7 w-6 text-[#1B3C1E]"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd>
                    Barbaros Mah. Kardelen Sk. Palladium Tower Apt.
                    <br />
                    No:2/25, 34746 Ataşehir/İstanbul
                  </dd>
                </div>
                {/* <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon className="h-7 w-6 text-[#1B3C1E]" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-[#1E1E1E]" href="tel:+1 (555) 234-5678">
                    +1 (555) 234-5678
                  </a>
                </dd>
              </div> */}
                {/* <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon className="h-7 w-6 text-[#1B3C1E]" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-[#1E1E1E]" href="mailto:hello@example.com">
                    info@watespresso.com
                  </a>
                </dd>
              </div> */}
              </dl>
            </div>
          </div>
          <form
          id='contactForm'
            className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
            onSubmit={sendEmail}
            ref={form}
          >
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-semibold leading-6 text-[#1E1E1E]"
                  >
                    First name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-[#1E1E1E] shadow-sm outline-none placeholder:text-[#1E1E1E] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-semibold leading-6 text-[#1E1E1E]"
                  >
                    Last name
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-[#1E1E1E] shadow-sm outline-none placeholder:text-[#1E1E1E] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-[#1E1E1E]"
                  >
                    Email
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-[#1E1E1E] shadow-sm outline-none placeholder:text-[#1E1E1E] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-semibold leading-6 text-[#1E1E1E]"
                  >
                    Phone number
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      autoComplete="tel"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-[#1E1E1E] shadow-sm outline-none placeholder:text-[#1E1E1E] sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-[#1E1E1E]"
                  >
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-[#1E1E1E] shadow-sm outline-none placeholder:text-[#1E1E1E] sm:text-sm sm:leading-6"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8 flex justify-end">
                <button
                  type="submit"
                  className="rounded-md bg-[#1B3C1E] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Send message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
