import kahvedunyasi from "../../assets/img/kahve-dunyasi.png";
import decathlon from "../../assets/img/decathlon.png";
import deliveryhero from "../../assets/img/delivery-hero.png";
import kastamonuentegre from "../../assets/img/kastamonu-entegre.png";
import tav from "../../assets/img/tav.png";

export default function index() {
  return (
    <div className="bg-[#1B3C1E] py-14 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-0">
        <h2 className="text-center text-[22px] md:text-[1.75rem] font-bold leading-8 text-white">
          Trusted by the world’s most innovative teams
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
            src={kahvedunyasi}
            alt="kahvedunyasi"
            width={178}
            height={58}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={decathlon}
            alt="decathlon"
            width={178}
            height={58}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={deliveryhero}
            alt="delivery hero"
            width={178}
            height={58}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={kastamonuentegre}
            alt="kastamonu entegre"
            width={178}
            height={58}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={tav}
            alt="tav"
            width={178}
            height={58}
          />
        </div>
      </div>
    </div>
  );
}
