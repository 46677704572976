import Footer from "../../components/Footer";
import ProductHero from "../../components/ProductHero";
import CarbonReporting from "../../components/CarbonReporting";
import Process from "../../components/Process";

export default function index() {
  return (
    <>
      <ProductHero />
      <CarbonReporting/>
      <Process/>
      <Footer />
    </>
  );
}
