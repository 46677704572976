import coffe from "../../assets/img/coffe.png";
import straws from "../../assets/img/straws.png";
import chipboard from "../../assets/img/chipboard.png";

export default function index() {
  return (
    <>
      <div className="bg-[#1B3C1E] border-b border-[#E1E1E180]">
        <div className="mx-auto max-w-7xl px-6 lg:px-0 py-16">
          <div className="flex flex-col items-center justify-center gap-8">
            <h2 className="text-[#E1E1E1] text-[20px] md:text-[4rem] font-bold uppercase">
              Reusable Coffee Cup
            </h2>
            <img src={coffe} className="h-36 md:h-96" alt="coffe" />
            <ul className="text-[#E1E1E1] text-center text-[11px] md:text-[2rem]">
              <li>It can be washed in the dishwasher.</li>
              <li>It is suitable for multi-usage for three years.</li>
              <li>It is suitable for both hot and cold beverages.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#1B3C1E] border-b border-[#E1E1E180]">
        <div className="mx-auto max-w-7xl px-6 lg:px-0 py-16">
          <div className="flex flex-col items-center justify-center gap-8">
            <h2 className="text-[#E1E1E1] text-[20px] md:text-[4rem] font-bold uppercase">
              Straws
            </h2>
            <img src={straws} className="h-36 md:h-96" alt="coffe" />
            <ul className="text-[#E1E1E1] text-center text-[11px] md:text-[2rem]">
              <li>They are single-use straws.</li>
              <li>
                They do not deteriorate when contacting the mouth or the
                beverage.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#1B3C1E]">
        <div className="mx-auto max-w-7xl px-6 lg:px-0 py-16">
          <div className="flex flex-col items-center justify-center gap-8">
            <h2 className="text-[#E1E1E1] text-[20px] md:text-[4rem] font-bold uppercase">
              Chipboard
            </h2>
            <img src={chipboard} className="h-36 md:h-96" alt="coffe" />
            <ul className="text-[#E1E1E1] text-center text-[11px] md:text-[2rem]">
              <li>
                Another product category includes items directly manufactured
                from spent coffee grounds. In collaboration with Kastamonu
                Entegre, we have created chipboards utilizing coffee waste.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
