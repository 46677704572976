import carbon from "../../assets/img/carbon.webp";
export default function index() {
  return (
    <div className="bg-[#1B3C1E] relative overflow-hidden">
      <div className="flex justify-between items-center gap-0 md:gap-24">
        <div className="w-2/3">
          <div className="mx-auto max-w-2xl flex justify-center flex-col gap-4 px-4 md:px-0 py-12 md:py-24">
            <h1 className="text-left md:text-center text-[#E1E1E1] text-[24px] md:text-[4rem] font-bold leading-tight">
              CARBON REPORTING
            </h1>
            <p className="text-left  md:text-center text-[#E1E1E1] text-[11px] md:text-[1.5rem] font-medium">
              In our commitment to transparency and sustainability, we generate
              a monthly report on carbon emission reduction for each partner
              participating in the micro waste management system. Utilizing
              specialized software, we calculate the reduction in carbon
              emissions achieved throughout our operations. This comprehensive
              analysis considers diverse parameters within the scope of the
              Green House Protocol and can be utilized in sustainability
              reports. The aim is to provide partners with a clear and detailed
              account of their environmental impact, fostering a shared
              commitment to reducing our carbon footprint.
            </p>
          </div>
        </div>
        <div className="w-1/3">
          <img src={carbon} className="w-auto h-auto" alt="hero" />
        </div>
      </div>
    </div>
  );
}
