import { NavLink } from "react-router-dom";

export default function index() {
  return (
    <div className="bg-[#1B3C1E] py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-0">
        <h2 className="text-center text-[22px] md:text-[1.75rem] font-bold leading-8 text-white">
          Trusted by the world’s most innovative teams
        </h2>
       <div className="text-center mt-4 md:mt-12">
       <NavLink to='/about-us' className="rounded-[1.3125rem] border-[3px] w-fit border-white text-white md:px-8 md:py-4 uppercase text-[11px] py-2 px-4 md:text-[1.5rem]">Learn More</NavLink>
       </div>
      </div>
    </div>
  );
}
