import process1 from "../../assets/img/process-icon1.png";
import process2 from "../../assets/img/process-icon2.png";
import process3 from "../../assets/img/process-icon3.png";
import arrowup from "../../assets/img/ri_arrow-up-fill.png";

export default function index() {
  return (
    <div className="bg">
      <div className="max-w-7xl mx-auto px-6 lg:px-0 py-16">
        <div className="flex flex-col gap-6 justify-center">
          <div className="text-center">
            <h2 className="text-[#1B3C1E] text-[28px] md:text-[4rem] font-bold">
              The process of operation
            </h2>
          </div>
          <div className="flex flex-col md:flex-row items-center md:items-baseline gap-6 py-3 md:py-12">
            <div className="w-full flex flex-col justify-center items-center gap-6">
              <img src={process1} className="h-24 w-42 md:h-36 md:w-36" alt="process" />
              <p className="max-w-sm md:max-w-none text-center text-[#1B3C1E] text-[24px] md:text-xl font-bold">
                Spent coffee grounds are gathered in your facilities.
              </p>
            </div>
            <div>
              <img src={arrowup} className="w-16 rotate-90 md:rotate-0 md:w-auto md:h-auto" alt="arrowup" />
            </div>
            <div className="w-full flex flex-col justify-center items-center gap-6">
              <img src={process2} className="h-24 w-24 md:h-36 md:w-36" alt="process" />
              <p className="max-w-sm md:max-w-none text-center text-[#1B3C1E] text-[24px] md:text-xl font-bold">
                Those coffee grounds are collected by our trucks weekly.
              </p>
            </div>
            <div>
              <img src={arrowup} className="w-16 rotate-90 md:rotate-0 md:w-auto md:h-auto" alt="arrowup" />
            </div>
            <div className="w-full flex flex-col justify-center items-center gap-6">
              <img src={process3} className="h-24 w-24 md:h-36 md:w-36" alt="process" />
              <p className="max-w-sm md:max-w-none text-center text-[#1B3C1E] text-[24px] md:text-xl font-bold">
                The up-cycling process begins for coffee grounds, and monthly
                carbon reports are shared.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
