import { NavLink } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import granule from "../../assets/img/espresso-granule.png";
import { useState } from "react";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { MdOutlineMenu } from "react-icons/md";

export default function Index() {
  const [dropdownMenu, setDropdownMenu] = useState(false);
  return (
    <div className="bg relative overflow-hidden">
      <div className="block md:hidden bg-[#1B3C1E] max-w-4xl rounded-br-[1.875rem]">
        <div className="px-6 py-3">
          <div className="relative flex items-center justify-center md:justify-normal gap-16">
            <div className="block md:hidden absolute left-0">
              <div
                onClick={() => setDropdownMenu(!dropdownMenu)}
                className="bg-white p-2 rounded-lg"
              >
                <MdOutlineMenu className="w-6 h-6 text-[#1B3C1E]" />
              </div>
              {dropdownMenu && (
                <div className="absolute bg-white rounded-md w-48 mt-2 z-10">
                  <ul>
                    <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                      <NavLink to="/micro-waste-managment">
                        Micro Waste Managment
                      </NavLink>
                    </li>
                    <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                      <NavLink to="/upcycled-products">
                        Upcycled Products
                      </NavLink>
                    </li>
                    <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                      <NavLink to="/espresso-granule">Espresso Granule</NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <NavLink to="/">
              {" "}
              <img src={logo} className="h-12" alt="logo" />
            </NavLink>
            <nav className="hidden md:block">
              <ul className="flex items-center gap-8">
                <li className="text-white uppercase text-lg font-semibold">
                  <NavLink to="/about-us">ABOUT US</NavLink>
                </li>
                <li className="text-white uppercase text-lg font-semibold relative">
                  <NavLink
                    onClick={() => setDropdownMenu(!dropdownMenu)}
                    className="flex gap-2 items-center"
                  >
                    PRODUCTS & SERVICES <ArrowDownIcon className="w-4 h-4" />
                  </NavLink>
                  {dropdownMenu && (
                    <div className="absolute bg-white rounded-md w-full">
                      <ul>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/micro-waste-managment">
                            Micro Waste Managment
                          </NavLink>
                        </li>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/upcycled-products">
                            Upcycled Products
                          </NavLink>
                        </li>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/espresso-granule">
                            Espresso Granule
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-24">
        <div className="w-full md:w-2/3">
          <div className="hidden md:block bg-[#1B3C1E] rounded-br-[1.875rem]">
            <div className="px-6 py-3">
              <div className="flex items-center gap-16">
                <NavLink to="/">
                  {" "}
                  <img src={logo} className="h-12" alt="logo" />
                </NavLink>
                <nav>
                  <ul className="flex items-center gap-8">
                    <li className="text-white uppercase text-lg font-semibold">
                      <NavLink to="/about-us">ABOUT US</NavLink>
                    </li>
                    <li className="text-white uppercase text-lg font-semibold relative">
                      <NavLink
                        onClick={() => setDropdownMenu(!dropdownMenu)}
                        className="flex gap-2 items-center"
                      >
                        PRODUCTS & SERVICES{" "}
                        <ArrowDownIcon className="w-4 h-4" />
                      </NavLink>
                      {dropdownMenu && (
                        <div className="absolute bg-white rounded-md w-full">
                          <ul>
                            <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                              <NavLink to="/micro-waste-managment">
                                Micro Waste Managment
                              </NavLink>
                            </li>
                            <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                              <NavLink to="/upcycled-products">
                                Upcycled Products
                              </NavLink>
                            </li>
                            <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                              <NavLink to="/espresso-granule">
                                Espresso Granule
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 py-8 md:py-24 justify-center items-center">
            <h1 className="text-[#1E1E1E] text-[24px] md:text-[4rem] font-bold leading-tight uppercase">
              Espresso Granule
            </h1>
            <p className="w-full md:w-[70%] text-[#1E1E1E] text-[11px] md:text-[1.5rem] px-6 md:px-0 font-medium text-center md:text-left">
              Through the utilization of the "Espresso Granule" raw material, we
              actively assist businesses in empowering them to cultivate a
              sustainable product portfolio.
              <br />
              <br /> Leveraging our advanced technological infrastructure, we
              employ an upcycling process on spent coffee grounds to generate a
              bio-based raw material. Espresso Granule emerges as an
              eco-friendly alternative to conventional plastics.
              <br />
              <br /> Espresso Granule is applicable across diverse industries.
              It has been embraced across various sectors such as home
              appliances, automotive, pharmaceuticals, and FMCG. This
              demonstrates its adaptability and growing significance as a
              sustainable solution in the realm of raw materials.
            </p>
          </div>
        </div>
        <div className="hidden md:block w-1/3">
          <img src={granule} className="w-auto h-auto" alt="hero" />
        </div>
      </div>
    </div>
  );
}
