import arrowgold from "../../assets/img/arrowup-gold.png";
import materialicon1 from "../../assets/img/material-icon1.png";
import materialicon2 from "../../assets/img/material-icon2.png";
import materialicon3 from "../../assets/img/material-icon3.png";

export default function index() {
  return (
    <div className="bg-[#1B3C1E]">
      <div className="max-w-7xl mx-auto px-6 lg:px-0 py-16">
        <div className="flex flex-col gap-6 justify-center">
          <div className="text-center">
            <h2 className="text-[#B89067] text-[28px] md:text-[4rem] font-bold">
              The process of material
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 items-center gap-6 py-12">
            <div className="flex flex-col justify-center items-center gap-6">
              <img
                src={materialicon1}
                className="w-24 h-24 md:h-36 md:w-36"
                alt="process"
              />
              <p className="text-center text-[#B89067] text-[24px] md:text-xl font-bold">
                Coffe waste
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={arrowgold}
                className=" w-28 h-28 rotate-90 md:rotate-0 md:w-24 md:h-24"
                alt="arrowup"
              />
            </div>
            <div className="flex flex-col justify-center items-center gap-6">
              <img
                src={materialicon2}
                className="w-24 h-24 md:h-36 md:w-36"
                alt="process"
              />
               <p className="text-center text-[#B89067] text-[24px] md:text-xl font-bold">
                Upcycling Process
              </p>
            </div>
            <div className="flex justify-center">
              <img src={arrowgold} className="w-28 h-28 rotate-90 md:rotate-0 md:h-36 md:w-36" alt="arrowup" />
            </div>
            <div className="flex flex-col justify-center items-center gap-6">
              <img
                src={materialicon3}
                className="w-24 h-24 md:h-36 md:w-36"
                alt="process"
              />
             <p className="text-center text-[#B89067] text-[24px] md:text-xl font-bold">
                Espresso Granule
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
