import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import instagram from "../../assets/img/instagram.png";
import linkedin from "../../assets/img/linkedin.png";

export default function index() {
  return (
    <div className="bg-[#1F0D00]">
      <div className="mx-auto max-w-7xl px-6 lg:px-0 py-12">
        <div className="flex flex-col gap-6">
          <div>
            <img src={logo} className="h-16" alt="logo" />
          </div>
          <div>
            <span className="text-gray-400 text-[1rem] font-medium">Copyright © 2024 Wastespresso</span>
          </div>
          <div className="flex items-center gap-4">
            <Link to='https://instagram.com/wastespresso.tr'>
                <img src={instagram} className='h-10' alt='instagram' />
            </Link>
            <Link to='https://linkedin.com/company/wastespresso-tr/'>
                <img src={linkedin} className='h-10' alt='linkedin' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
