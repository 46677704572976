import logo from "../../assets/img/logo.png";
import hero from "../../assets/img/hero-img.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { ArrowDownIcon } from "@heroicons/react/24/outline";
import { MdOutlineMenu } from "react-icons/md";

export default function Index() {
  const [dropdownMenu, setDropdownMenu] = useState(false);
  return (
    <div className="bg relative overflow-hidden">
      <div className="bg-[#1B3C1E] max-w-4xl rounded-br-[1.875rem]">
        <div className="px-6 py-3">
          <div className="relative flex items-center justify-center md:justify-normal gap-16">
            <div className="block md:hidden absolute left-0">
              <div onClick={()=> setDropdownMenu(!dropdownMenu)} className="bg-white p-2 rounded-lg">
                <MdOutlineMenu className="w-6 h-6 text-[#1B3C1E]" />
              </div>
              {dropdownMenu && (
                    <div className="absolute bg-white rounded-md w-48 mt-2 z-10">
                      <ul>
                        <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/micro-waste-managment">
                            Micro Waste Managment
                          </NavLink>
                        </li>
                        <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/upcycled-products">
                            Upcycled Products
                          </NavLink>
                        </li>
                        <li className="text-[#1B3C1E] font-semibold text-sm p-1.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/espresso-granule">
                            Espresso Granule
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
            </div>
            <NavLink to="/">
              {" "}
              <img src={logo} className="h-12" alt="logo" />
            </NavLink>
            <nav className="hidden md:block">
              <ul className="flex items-center gap-8">
                <li className="text-white uppercase text-lg font-semibold">
                  <NavLink to="/about-us">ABOUT US</NavLink>
                </li>
                <li className="text-white uppercase text-lg font-semibold relative">
                  <NavLink
                    onClick={() => setDropdownMenu(!dropdownMenu)}
                    className="flex gap-2 items-center"
                  >
                    PRODUCTS & SERVICES <ArrowDownIcon className="w-4 h-4" />
                  </NavLink>
                  {dropdownMenu && (
                    <div className="absolute bg-white rounded-md w-full z-10">
                      <ul>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/micro-waste-managment">
                            Micro Waste Managment
                          </NavLink>
                        </li>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/upcycled-products">
                            Upcycled Products
                          </NavLink>
                        </li>
                        <li className="text-black text-sm p-2.5 rounded-md hover:bg-gray-200">
                          <NavLink to="/espresso-granule">
                            Espresso Granule
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="py-24 px-6 max-w-4xl relative">
        <div className="flex flex-col gap-6">
          <h1 className="text-[#1E1E1E] text-[20px] md:text-[4rem] font-bold uppercase leading-tight">
            Your company is now{" "}
            <h1 className="text-[#116835]">more sustainable</h1> with
            wastespresso
          </h1>
          <p className="text-[#1E1E1E] text-[11px] w-1/2 md:w-full md:text-[1.5rem] font-medium">
            We produce bioplastic compounds and products from spent coffee
            grounds.
          </p>
          <NavLink
            to="https://share-eu1.hsforms.com/10rShIW4xSXWZyZf_jtiFdQ2b9mip"
            target="_blank"
            className="bg-[#1B3C1E] text-white rounded-[1.3125rem] w-fit md:px-8 px-4 py-3 md:py-4 uppercase text-[14px] md:text-[2rem] font-bold"
          >
            Contact Us
          </NavLink>
        </div>
      </div>
      <div className="absolute -right-[20rem] md:right-0 top-0">
        <img src={hero} className="w-[27.5rem] md:w-[29rem]" alt="hero" />
      </div>
    </div>
  );
}
