import HeroSection from '../../components/HeroSection'
import Logos from '../../components/Logos'
import WhatIs from '../../components/WhatIs'
import CallToAction from '../../components/CallToAction'
import Footer from '../../components/Footer'
import OurProcess from '../../components/OurProcess'
export default function index() {
  return (
    <>
    <HeroSection/>
    <Logos/>
    <WhatIs/>
    <CallToAction/>
    <OurProcess/>
    <Footer/>
    </>
  )
}
